






































import Vue from 'vue';
import ConfirmDialog from '~/components/common/ConfirmDialog.vue';
import TooltipButton from '~/components/common/TooltipButton.vue';
import {Randomizer} from '~/util';

export default Vue.extend({
  name: 'AuthorizeAllPreExams',
  components: {TooltipButton, ConfirmDialog},
  props: {
    value: {type: Boolean, default: false},
    studentId: {type: String},
  },
  data() {
    return {
      confirmDialog: false,
      form: {
        state: 'authorized',
        autoInsight: true,
        password: null,
      },
    };
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async authorizeAllPreExams() {
      try {
        const payload = {studentId: this.studentId, form: this.form};
        await this.$store.dispatch('student/authorizeAllPreExams', payload);
        this.dialog = false;
      } catch (err) {
        await this.$store.dispatch('snackbar/showError', err);
      }
    },
    close() {
      this.dialog = false;
    },
    generatePassword() {
      this.form.password = Randomizer.generateRandomStringOfLength(10);
    },
  },
});
