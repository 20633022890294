



































import Vue from 'vue';
import {Test, TestGroup} from '~/models';

export default Vue.extend({
  name: 'AssignPreExamDialog',
  props: {
    value: {type: Boolean, default: false},
    student: {type: Object},
    target: {type: String, default: ''},
  },
  data() {
    return {
      search: '',
      testGroup: -1,
      allChecked: false,
      form: {
        tests: [],
      },
    };
  },
  watch: {
    student() {
      if (this.student) {
        const assignedTests = this.student.studentTestStates.map(sts => sts.test);
        this.form.tests = [].concat(assignedTests);
      }
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    someSelected(): boolean {
      return this.form.tests.length > 0 && !this.allChecked;
    },
    testGroups(): TestGroup[] {
      return [
        {id: -1, title: this.$t('label.all')},
        ...this.$store.state.auth.school.testGroups,
      ];
    },
    selectedTestCount(): number {
      return this.form.tests.filter(test => this.availablePreExams.find(t => t.id === test.id)).length;
    },
    availablePreExams(): Test[] {
      const searchTerms = this.search.toLowerCase().split(' ');
      const tests = this.$store.state.auth.school.tests;
      const allTests = this.$store.state.auth.school.testGroups.reduce((arr, tG) => arr.concat(tG.tests), tests);
      return allTests.filter(test => test.isPreExam)
          .filter(test => this.testGroup === -1 || this.testGroups.find(tg => tg.id === this.testGroup).tests.find(t => t.id === test.id))
          .filter((test, index, self) => self.findIndex(t => t.id === test.id) === index)  // filter unique
          .filter(test => searchTerms.reduce((result, term) => result && test.title.toLowerCase().includes(term), true));
    },
    preExamHeaders(): any[] {
      return [
        {text: this.$t('label.name'), value: 'displayName'},
      ];
    },
  },
  methods: {
    async submit() {
      try {
        const payload = {studentId: this.student.id, testIds: this.form.tests.map(test => test.id)};
        await this.$store.dispatch('student/assignPreExams', payload);
        this.dialog = false;
        this.search = '';
      } catch (err) {
        await this.$store.dispatch('snackbar/showError', err);
      }
    },
    onItemSelect(event) {
      this.$nextTick(() => {
        this.allChecked = this.form.tests.length === this.availablePreExams.length;
      });
    },
    toggleAll(event) {
      const allAvailableSelected = this.availablePreExams.reduce((allSelected, pe) => allSelected && this.form.tests.find(t => t.id === pe.id), true);
      if (allAvailableSelected) {
        this.form.tests = this.form.tests.filter(test => !this.availablePreExams.find(pe => pe.id === test.id));
        this.allChecked = false;
      } else {
        this.form.tests = this.form.tests.concat(this.availablePreExams.filter(pe => !this.form.tests.find(t => t.id === pe.id)));
        this.allChecked = true;
      }
      event.preventDefault();
    },
    cancel() {
      this.dialog = false;
      this.search = '';
      const assignedTests = this.student.studentTestStates.map(sts => sts.test);
      this.form.tests = [].concat(assignedTests);
    },
  },
});
